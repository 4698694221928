import React from "react";

import styled from "../../theme/styled";
import {
  colourHelper,
  fontLineHeightHelper,
  fontSizeHelper,
  fontWeightHelper
} from "../../theme/theme-helper";
import { SmallColumnWidthWrapper } from "../confines-wrappers";
import Link from "../link";

interface IProps {
  filter: string;
  clearLink: string;
  type: string;
}

const FiltersAppliedWrapper = styled.aside`
  transition: 0.2s ease-in-out;
  padding-top: 3.2rem;
  padding-bottom: 2.8rem;
  border-bottom: 0.2rem solid
    ${({ theme }) => colourHelper({
    theme,
    variant: "lightestGrey"
  })};

  .text {
    margin-bottom: 0;
    text-align: center;
    font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_2"
  })};
    font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "light"
    })};
    line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "default"
    })};

    > span {
      display: block;
    }

    .showing {
      strong {
        display: block;
        font-weight: ${({ theme }) =>
    fontWeightHelper({
      theme,
      variant: "heavy"
    })};
      }
    }

    .clear-link {
      padding-top: 1.8rem;
      color: ${({ theme }) => colourHelper({
    theme,
    variant: "brandColor"
  })};
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
    }
  }

  @media (min-width: 480px) {
    .text {
      .showing {
        strong {
          display: inline;
        }
      }
    }
  }

  @media (min-width: 568px) {
    .text {
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_1"
    })};
    }

    .clear-link {
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
    }
  }

  @media (min-width: 960px) {
    padding-top: 3.8rem;
    padding-bottom: 3.4rem;

    .text {
      font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
    }
  }
`;

const FiltersApplied = ({
  filter, clearLink, type
}: IProps) => {
  return (
    <FiltersAppliedWrapper>
      <SmallColumnWidthWrapper>
        <p className="text">
          <span className="showing">
            Showing
            {" "}

            {type}
            s for:

            {" "}

            <strong>
              {filter}
            </strong>
          </span>

          <span className="off-screen">
            .
            <br />

            <br />
          </span>

          <span className="clear-link">
            <span className="off-screen">
              Click here to
              {" "}
            </span>

            <Link href={clearLink}>
              Clear Filters
            </Link>

            <span className="off-screen">
              .
            </span>
          </span>
        </p>
      </SmallColumnWidthWrapper>
    </FiltersAppliedWrapper>
  );
};

export default FiltersApplied;
