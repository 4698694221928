import * as React from "react";

import styled from "../../theme/styled";
import {
  fontLineHeightHelper,
  fontSizeHelper
} from "../../theme/theme-helper";
import { FullWidthColumnWrapper } from "../confines-wrappers";

interface IProps {
  className?: string;
  children: React.ReactElement;
}

const NoItemsFoundContainer = styled.aside`
  .no-content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 34.8rem;

    p {
      margin-bottom: 0;
      text-align: center;
      font-size: ${({ theme }) => fontSizeHelper({
    theme,
    variant: "body_1"
  })};
      line-height: ${({ theme }) =>
    fontLineHeightHelper({
      theme,
      variant: "small"
    })};
    }
  }

  @media (min-width: 568px) {
    .no-content-wrapper {
      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "body_2"
    })};
      }
    }
  }

  @media (min-width: 960px) {
    .no-content-wrapper {
      p {
        font-size: ${({ theme }) =>
    fontSizeHelper({
      theme,
      variant: "subhead_2"
    })};
      }
    }
  }
`;

/**
 * No items found class component
 */
const NoItemsFound = ({ className, children }: IProps) => {
  const classes = `no-content-container${className ? ` ${className}` : ""}`;

  return (
    <NoItemsFoundContainer className={classes}>
      <FullWidthColumnWrapper className="content-container no-content-wrapper">
        {children}
      </FullWidthColumnWrapper>
    </NoItemsFoundContainer>
  );
};

export default NoItemsFound;
